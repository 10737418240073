import React from "react";
import { graphql } from 'gatsby';

import Layout from '../components/layout'

const divStyle = {marginTop: "10px"};

const LessonsPage = ({ data }) => (
  <Layout>
    <h1>Lessons learned from feedback:</h1>{data.allNodeLearningExamples.edges.map(({ node }) => (<div style={divStyle}> <h3>{node.title}</h3> 
      {node.field_feedback && (<div> Feedback received: {node.field_feedback.value}<br/></div>)}
      What I've learned:
    <div dangerouslySetInnerHTML={{ __html: node.body.value }} /> </div>))}
  </Layout>
);

export const query = graphql` query allNodeLearningExamples {
  allNodeLearningExamples {
    edges {
      node {
        id
        title
        body {
          value
          format
          processed
          summary
        }
        field_feedback {
          value
        }
      }
    }
  }
} `;

export default LessonsPage;
